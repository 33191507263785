


















































import { Judge } from '@/entities/user/Judge'
import { trsl } from '@/i18n/lang'
import store from '@/store'
import { Vue, Component, Prop } from 'vue-property-decorator'
import JudgeLockedBadge from './JudgeLockedBadge.vue'

@Component({
    components: {
        JudgeLockedBadge,
    },
})
export default class JudgeList extends Vue {
    @Prop() judges!: Judge[]
    @Prop() height?: string

    fields = [
        {
            key: 'id',
            label: trsl('judge.property.ijudge-id'),
            sortable: true,
        },
        {
            key: 'enabled',
            label: trsl('judge.property.enabled'),
        },
        {
            key: 'assignedUser',
            label: trsl('judge.property.assigned-user'),
            sortable: true,
        },
        {
            key: 'expirationDate',
            label: trsl('judge.property.expiration-date'),
        },
        {
            key: 'locked',
            label: trsl('judge.property.locked'),
        },
        {
            key: 'unlock',
            label: '',
            tdClass: 'width-sm',
        },
    ]

    get displayJudges() {
        return this.judges.map(judge => {
            const user = store.getters.user.userWithJudgeId(judge.id)
            const minUser = user ? { id: user.id, name: `${user.firstname} ${user.lastname}` } : null
            const expirationDate = user?.judge?.expirationDate.format('DD.MM.YYYY HH:mm') || '-'
            return {
                id: judge.id,
                enabled: judge.isEnabled,
                locked: judge.isLocked,
                assignedUser: minUser,
                expirationDate: expirationDate,
            }
        })
    }

    unlock(id: number) {
        store.dispatch.message
            .showDialog({
                textKey: 'release-judge',
                confirmKey: 'release',
            })
            .then(confirmed => {
                if (confirmed) {
                    store.dispatch.judge.releaseJudgeWithId(id)
                }
            })
    }

    hightlightedId: number | null = null
    timeout: number | undefined = undefined
    highlight(judgeId: number) {
        clearTimeout(this.timeout)
        this.hightlightedId = judgeId
        this.timeout = setTimeout(() => (this.hightlightedId = null), 3000)
    }

    rowClass(item: { id: number }) {
        if (item.id == this.hightlightedId) {
            return 'blink'
        }
    }
}
