
















import store from '@/store'
import { Vue, Component, Watch } from 'vue-property-decorator'
import JudgeList from '@/components/judge/JudgeList.vue'

@Component({
    components: {
        JudgeList,
    },
})
export default class JudgeView extends Vue {
    $refs!: {
        judgeList: JudgeList
    }

    mounted() {
        this.fetchJudges()
        store.dispatch.user.fetchUsers()
        this.highlightSelected()
    }

    destroyed() {
        store.dispatch.judge.stopPeriodicFetching()
    }

    fetchJudges() {
        store.dispatch.judge.fetchJudgesPeriodically(15000)
    }

    get lastFetch() {
        return store.getters.judge.lastFetch
    }

    get judges() {
        return store.getters.judge.judges
    }

    get selectedJudgeId(): number | null {
        const idString = this.$route.query['judgeId'] as string | null
        return idString ? parseInt(idString) : null
    }

    @Watch('selectedJudgeId')
    highlightSelected() {
        if (this.selectedJudgeId != null) {
            this.$refs.judgeList.highlight(this.selectedJudgeId)
            this.$router.replace({ query: { judgeId: undefined } })
        }
    }
}
